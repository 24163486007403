<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">
              Dashboard
            </h4>
          </template>
          <template>
            <div class="row mb-3" >
              <div class="col-lg-3" v-for="(item, index) in colorCodeList" :key="item.colorCodeList">
                <div class="card text-white iq-mb-2 mb-3" :style="getBgColor(index)">
                  <div class="card-body">
                    <h4 class="card-title text-white" style="text-align:center">
                      {{item}}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- <template>
            <b-badge variant="primary" class="text-wrap w-125px" :style="getAdmissionStateColorCode(data.item.adm_state)">
              {{admissionStateInfo[data.item.adm_state]}}
            </b-badge><br/>

            colorCodeList: ["#3366CC", "#DC3912", "#FF9900", "#109618", "#990099", "#3B3EAC", "#0099C6", "#DD4477", "#66AA00", "#B82E2E", "#316395", "#994499", "#22AA99", "#AAAA11", "#6633CC", "#E67300", "#8B0707", "#329262", "#5574A6", "#3B3EAC", "#2d5bb7", "#c63310", "#e58900", "#0e8715", "#890089", "#35379a", "#0089b2", "#c63d6b", "#5b9900", "#a52929", "#2c5986", "#893d89", "#1e9989", "#99990f", "#5b2db7", "#cf6700", "#7d0606", "#2d8358", "#4c6895", "#35379a", "#2851a3", "#b02d0e", "#cc7a00", "#0c7813", "#7a007a", "#2f3189", "#007a9e", "#b0365f", "#518800", "#932424", "#274f77", "#7a367a", "#1b887a", "#88880d", "#5128a3", "#b85c00", "#6f0505", "#28744e", "#445c84", "#2f3189", "#23478e", "#9a270c", "#b26b00", "#0b6910", "#6b006b", "#292b78", "#006b8a", "#9a2f53", "#477600", "#802020", "#224568", "#6b2f6b", "#17766b", "#76760b", "#47238e", "#a15000", "#610404", "#236644", "#3b5174", "#292b78", "#1e3d7a", "#84220a", "#995b00", "#095a0e", "#5b005b", "#232567", "#005b76", "#842847", "#3d6600", "#6e1b1b", "#1d3b59", "#5b285b", "#14665b", "#66660a", "#3d1e7a", "#8a4500", "#530404", "#1e573a", "#334563", "#232567", "#193366", "#6e1c09", "#7f4c00", "#084b0c", "#4c004c", "#1d1f56", "#004c63", "#6e223b", "#335500", "#5c1717", "#18314a", "#4c224c", "#11554c", "#555508", "#331966", "#733900", "#450303", "#194931", "#2a3a53", "#1d1f56", "#142851", "#581607", "#663d00", "#063c09", "#3d003d", "#171844", "#003d4f", "#581b2f", "#284400", "#491212", "#13273b", "#3d1b3d", "#0d443d", "#444406", "#281451", "#5c2e00", "#370202", "#143a27", "#222e42", "#171844"]
          </template> -->
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import ColorCodes from "../../Utils/colorcodes"

export default {
  name: "Dashboard",
  data () {
    return {
      colorCodeList: ColorCodes.colorCodeList
    }
  },
  mounted () {
  },
  methods: {
    /**
     * getBgColor
     */
    getBgColor (index) {
      return ColorCodes.getBgColor(index)
    }
  }
}
</script>
